import styled from 'styled-components'

export const LightMainIntro = styled.section`
  max-width: 1440px;
  padding: 100px 15px 299px 15px;
  margin: 0 auto;
  background: #f3f4f6;
  border-radius: 0px 0px 40px 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 94px 52px 50px 52px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 185px 108px 95px 108px;
    border-radius: 0 0 80px 80px;
  }
`
export const IntroContent = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 340px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 525px;
  }
`

export const IntroCaption = styled.p`
  ${({ theme }) => theme.mixins.text4}
  color: ${({ theme }) => theme.colors.black};
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.text5}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text2}
  }
`

export const IntroTitle = styled.h1`
  ${({ theme }) => theme.mixins.H3}
  color: ${({ theme }) => theme.colors.black};
  margin-top: 5px;
  max-width: 231px;
  @media ${({ theme }) => theme.devices.noteUp} {
    font-size: 68px;
    line-height: 82px;
    margin-top: 10px;
    max-width: initial;
  }
`

// Awards
export const IntroAwards = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 33.5px;
`

export const IntroAwardsItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    gap: 8px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    gap: 15px;
  }
`

export const IntroAwardsItemImg = styled.div`
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 23.79px;
    height: 23.79px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 40px;
    height: 40px;
  }
`

export const IntroAwardsItemText = styled.p`
  position: relative;
  color: #5e5e5e;
  font-weight: 500;
  font-size: 10px;
  line-height: 122.5%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.text5}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text2}
  }
`

export const IntroLink = styled.a`
  ${({ theme }) => theme.mixins.H7}
  text-decoration: none;
  color: #fff;
  height: 34px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  position: relative;
  background: #18a866;
  width: 100%;
  transition: 0.3s;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 193px;
    height: 32px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H6}
    max-width: 290px;
    height: 45px;
  }

  &:hover {
    background-color: #fff;
    color: #18a866;
    border: 1px solid #18a866;
  }
`
// Button

export const IntroButtonWrapper = styled.div`
  width: 100%;
  margin-top: 18px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 25px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 46px;
  }
`

// background blocks
export const IntroProfessionsWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 304px;
  height: 320px;
  right: 0;
  bottom: -35px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    right: 0;
    bottom: 0;
    width: 358px;
    height: 406px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 708px;
    height: 803px;
    right: 115px;
    top: -65px;
  }
`

export const IntroProfession = styled.div`
  position: absolute;
  border-radius: 7.2px;
  width: 100%;
  max-width: 98px;
  min-height: 87px;
  padding: 14px 10px 17px 9px;
  background: ${({ theme }) => theme.colors.grey200};
  &:nth-child(1) {
    left: 20px;
    bottom: 50px;
  }
  &:nth-child(2) {
    left: 137px;
    top: 3px;
  }
  &:nth-child(3) {
    left: 117px;
    bottom: 61px;
  }
  &:nth-child(4) {
    right: -11px;
    bottom: 142px;
  }
  &:nth-child(5) {
    right: -26px;
    bottom: 0px;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 120px;
    min-height: 105px;
    &:nth-child(1) {
      left: 0px;
      bottom: -16px;
    }
    &:nth-child(2) {
      left: 42px;
      top: 73px;
    }
    &:nth-child(3) {
      left: 120px;
      bottom: 22px;
    }
    &:nth-child(4) {
      right: 51px;
      bottom: 116px;
    }
    &:nth-child(5) {
      right: -46px;
      bottom: -45px;
    }
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 221px;
    min-height: 198px;
    padding: 33px 24px 33px 20px;
    border-radius: 16px;
    &:nth-child(1) {
      left: 20px;
      bottom: -30px;
    }
    &:nth-child(2) {
      left: 50%;
      transform: translateX(-50%);
      top: 0px;
    }
    &:nth-child(3) {
      left: 50%;
      transform: translateX(-48%);
      bottom: 152px;
    }
    &:nth-child(4) {
      right: 0;
      bottom: 284px;
    }
    &:nth-child(5) {
      right: 0;
      bottom: -30px;
    }
  }
`

export const IntroProfessionTitle = styled.p<{ opacity: number }>`
  display: inline-block;
  vertical-align: top;
  text-align: center;
  border: 0.3px solid ${({ theme }) => theme.colors.black};
  ${props => `opacity: ${props.opacity};`}
  font-family:'PTRootUI';
  font-size: 0.375rem;
  font-weight: 400;
  line-height: 7.154px;
  padding: 3px 6px 2px;
  border-radius: 14px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 3px 6px;
    ${({ theme }) => theme.mixins.text5}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 6px 13px;
    border-radius: 32px;
    border: 1px solid ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.mixins.text4}
  }
`

export const IntroProfessionDescription = styled.p<{ opacity: number }>`
  ${props => `opacity: ${props.opacity};`}
  margin-top: 6px;
  ${props => `opacity: ${props.opacity};`}
  font-family:'PTRootUI';
  font-size: 0.375rem;
  font-weight: 400;
  line-height: 7.154px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 16px;
    ${({ theme }) => theme.mixins.text5}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text3}
  }
`

export const IntroProfessionArrow = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  right: 6.8px;
  bottom: 6px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    right: 11px;
    bottom: 10px;
    width: 20px;
    height: 20px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    right: 15px;
    bottom: 14px;
    width: 30px;
    height: 30px;
  }
`

// background images
export const InstallmentButton = styled.div`
  background: rgba(255, 255, 255, 1);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-left: auto;
  z-index: 4;
  width: 21.71px;
  height: 21.71px;
  path {
    stroke: ${({ theme }) => theme.colors.primary};
  }
  svg {
    width: 10px;
    height: 10px;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 24.77px;
    height: 24.77px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 49px;
    height: 49px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const InstallmentButtonHoverEffect = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.39);
  border-radius: 100%;
  display: none;

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 213px;
    height: 213px;
    right: -16%;
    bottom: -17%;
  }
`

export const Installment = styled.div`
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 155px;
  top: 370px;
  z-index: 3;
  transform: rotate(11deg);
  box-shadow: -55px 55px 75px rgba(0, 0, 0, 0.15);
  background: linear-gradient(112.58deg, #f7eb9e 10.11%, #b3edff 96.08%);
  border-radius: 15px;
  width: 155.35px;
  height: 155.35px;
  overflow: hidden;
  padding: 14.28px 11.65px 13.43px 12.64px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    right: 250px;
    top: 30px;
    width: 177px;
    height: 150px;
    padding: 19.72px 13.2px 15.41px 17.7px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    right: 390px;
    top: 45px;
    width: 350px;
    height: 280px;
    padding: 39px 26.11px 30.48px 35px;
  }
`

export const InstallmentTitle = styled.h3`
  ${({ theme }) => theme.mixins.H6}
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H3}
  }
`

export const InstallmentIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8.78px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 8px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 19px;
  }
`

export const InstallmentTinkoff = styled.div`
  margin-right: 11.59px;
  width: 68.51px;
  height: 17.81px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-right: 13.26px;
    width: 75.83px;
    height: 19.72px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-right: 26.24px;
    width: 150px;
    height: 39px;
  }
`

export const InstallmentSber = styled.div`
  width: 40.12px;
  height: 10.84px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 44.4px;
    height: 12px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 87.83px;
    height: 23.73px;
  }
`

export const InstallmentSplit = styled.div`
  margin-top: 9.68px;
  width: 69px;
  height: 9.92px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 11px;
    width: 76.43px;
    height: 10.98px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 21.82px;
    width: 151.19px;
    height: 21.72px;
  }
`

export const IntroBg3 = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 0;
  z-index: 5;
  width: 202px;
  height: 263px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    right: 98px;
    width: 241px;
    height: 312px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 477px;
    height: 618px;
  }
`
