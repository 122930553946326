import React from 'react'
import * as Styled from './styled'
import Image from 'next/image'
import getConfig from 'next/config'
import Link from 'next/link'

const {
  publicRuntimeConfig: { STATIC_ASSETS_COMMON_URL },
} = getConfig()

const data = [
  {
    alt: 'icon',
    description: 'Состоит в реестре отечественного ПО',
    imagePath: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-icon-v1.svg`,
    imageWidth: 40,
    imageHeight: 40,
  },
  {
    alt: 'icon',
    description: 'Образовательная лицензия Минобранауки',
    imagePath: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-icon-v2.svg`,
    imageWidth: 40,
    imageHeight: 40,
  },
]
const profession = [
  {
    title: 'Криптовалюта',
    description: 'Успешный скальпинг криптовалют',
    opacity: 0.2,
  },
  {
    title: 'Программирование',
    description: 'Roblox с нуля',
    opacity: 0.8,
  },
  {
    title: 'Бизнес',
    description: 'Посредничество на фрилансе',
    opacity: 0.4,
  },
  {
    title: 'Маркетинг',
    description: 'Копирайтер от А до Я',
    opacity: 0.6,
  },
  {
    title: 'Дизайн',
    description: 'Figma с нуля',
    opacity: 0.3,
  },
]
export const LightMainIntro = () => {
  return (
    <Styled.LightMainIntro>
      <Styled.IntroContent>
        <Styled.IntroCaption>
          WillSkill - доступно, просто, удобно
        </Styled.IntroCaption>
        <Styled.IntroTitle>Освойте востребованную профессию</Styled.IntroTitle>
        <Styled.IntroAwards>
          {data.map((item, index) => (
            <Styled.IntroAwardsItem key={index}>
              <Styled.IntroAwardsItemImg>
                <Image
                  alt={item.alt}
                  src={item.imagePath}
                  width={item.imageWidth}
                  height={item.imageHeight}
                  priority
                />
              </Styled.IntroAwardsItemImg>
              <Styled.IntroAwardsItemText>
                {item.description}
              </Styled.IntroAwardsItemText>
            </Styled.IntroAwardsItem>
          ))}
        </Styled.IntroAwards>

        <Styled.IntroButtonWrapper>
          <Link href='/#catalogue' passHref>
            <Styled.IntroLink>Выбрать курс</Styled.IntroLink>
          </Link>
        </Styled.IntroButtonWrapper>
      </Styled.IntroContent>

      <Styled.IntroProfessionsWrapper>
        {profession.map(({ title, description, opacity }, index) => {
          return (
            <Styled.IntroProfession key={index}>
              <Styled.IntroProfessionTitle opacity={opacity}>
                {title}
              </Styled.IntroProfessionTitle>
              <Styled.IntroProfessionDescription opacity={opacity}>
                {description}
              </Styled.IntroProfessionDescription>
              <Styled.IntroProfessionArrow>
                <Image
                  alt='arrow'
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-profession-arrow.svg`}
                  width={30}
                  height={30}
                  priority
                />
              </Styled.IntroProfessionArrow>
            </Styled.IntroProfession>
          )
        })}
      </Styled.IntroProfessionsWrapper>

      <Styled.Installment>
        <Styled.InstallmentTitle>
          В рассрочку от партнеров
        </Styled.InstallmentTitle>
        <Styled.InstallmentIconWrapper>
          <Styled.InstallmentTinkoff>
            <Image
              alt='tinkoff'
              src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-tinkoff.png`}
              width={150}
              height={39}
              priority
            />
          </Styled.InstallmentTinkoff>
          <Styled.InstallmentSber>
            <Image
              alt='sber'
              src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-sber.png`}
              width={87.83}
              height={23.73}
              priority
            />
          </Styled.InstallmentSber>
        </Styled.InstallmentIconWrapper>
        <Styled.InstallmentSplit>
          <Image
            alt='split'
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-split.png`}
            width={151.19}
            height={21.72}
            priority
          />
        </Styled.InstallmentSplit>
      </Styled.Installment>
      <Styled.IntroBg3>
        <Image
          alt='bg'
          src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-bg-3.png`}
          width={477}
          height={618}
          priority
        />
      </Styled.IntroBg3>
    </Styled.LightMainIntro>
  )
}

export default LightMainIntro
